import type Logger from "bunyan"
import { FramePostWithJson } from "./types"
import { Blog } from "@types"

export class FrameURLs {
  /**
   * The logger object.
   */
  private logger: Logger

  /**
   * The blog object. Must contain some minimum set of fields (see the type).
   */
  private blog: Blog

  /**
   * The domain of the API, e.g. https://paragraph.xyz
   * Fetched in the constructor from the blog object, so no need to pass it in.
   */
  private domain: string

  /**
   * The post object. Must contain some minimum set of fields (see the type).
   * This is an optional field because if the frame was generated for a blog and not a post, this field will be undefined.
   */
  private post?: FramePostWithJson

  /*****-----------------*****/
  /*****   CONSTRUCTOR   *****/
  /*****-----------------*****/

  /**
   * Creates a new FrameResponse object.
   * @param logger The logger object.
   * @param blog The blog object. Must contain some minimum set of fields (see the type).
   * @param blogOwnerUser The user object for the blog owner. Must contain some minimum set of fields (see the type).
   * @param post The post object. Must contain some minimum set of fields (see the type).
   *             If the frame response is for a blog and not a post, this field will be undefined.
   * @param isPostLevelGate Whether the post is post-level gated. If true, the "read inline" button will not be displayed.
   * @param currentPage The current page of the post. Can be undefined if we're not dealing with inline reading.
   * @param totalPageCount The total number of pages in the post. Can be undefined if we're not dealing with inline reading.
   * @param blogRecommendations Publications recommended by this blog. Since not all paths will lead to the recommendations page
   *                            being generated, this is an optional field.
   */
  constructor({
    logger,
    blog,
    domain,
    post,
  }: {
    logger: Logger
    blog: Blog
    domain: string
    post?: FramePostWithJson
  }) {
    // Required fields.
    this.logger = logger
    this.blog = blog
    this.domain = domain

    // Optional fields.
    this.post = post
  }

  /*****-----------------*****/
  /*****   URL METHODS   *****/
  /*****-----------------*****/

  /**
   * Generates the URL for the endpoint that returns the first frame page.
   * Useful when navigating back to the original page.
   * @returns Returns the URL for the origin post.
   */
  origin() {
    if (!this.post) {
      return `${this.domain}/api/farcaster/v2/homepage/blog/${this.blog.id}`
    }

    return `${this.domain}/api/farcaster/v2/homepage/blog/${this.blog.id}/post/${this.post.id}`
  }

  openPost() {
    if (!this.post) {
      this.logger.info(
        "Cannot generate URL for opening the post because the post is undefined."
      )

      return this.origin()
    }

    const viewPostUrl = `https://paragraph.xyz/view/${
      this.blog.lowercase_url
    }/${this.post.slug || this.post.id}`

    const composerActionUrl = encodeURIComponent(
      `https://api.paragraph.xyz/farcaster/v2/action?url=${viewPostUrl}`
    )

    return `https://warpcast.com/~/composer-action?url=${composerActionUrl}`
  }

  /**
   * Generates the URL for reading the previous page of a post (in-frame).
   * @returns Returns the URL for reading the previous page of a post.
   */
  readPostBack(currentPage?: number) {
    if (!this.post) {
      this.logger.error(
        "Cannot generate URL for reading the previous page of a post because the post is undefined."
      )

      return this.origin()
    }

    // If we're on the first page or not reading any page, this will go back to the origin post URL, starting the frame from scratch.
    if (currentPage === undefined || currentPage < 1) {
      return this.origin()
    }

    return `${this.domain}/api/farcaster/v2/read/blog/${this.blog.id}/post/${
      this.post.id
    }/${currentPage - 1}`
  }

  /**
   * Generates the URL for reading the next page of a post (in-frame).
   * @returns Returns the URL for reading the next page of a post.
   */
  readPostNext(currentPage: number) {
    if (!this.post) {
      this.logger.error(
        "Cannot generate URL for reading the next page of a post because the post is undefined."
      )

      return this.origin()
    }

    // If we're on page number -1 because we're not reading any page, this will correctly return the first page (0).
    return `${this.domain}/api/farcaster/v2/read/blog/${this.blog.id}/post/${
      this.post.id
    }/${currentPage + 1}`
  }

  /**
   * Generates the URL for reading a specific page of a post (in-frame).
   * @param page The page number to read.
   * @returns Returns the URL for reading a specific page of a post.
   */
  readPostPage(page: number) {
    if (!this.post) {
      this.logger.error(
        "Cannot generate URL for reading a specific page of a post because the post is undefined."
      )

      return this.origin()
    }

    return `${this.domain}/api/farcaster/v2/read/blog/${this.blog.id}/post/${this.post.id}/${page}`
  }

  /**
   * Generates the fc:frame:button:$idx:post_url property that allows a reader to subscribe to the blog.
   * @returns Returns the URL for subscribing to the blog.
   */
  subscribe() {
    const postIdAppend = this.post ? `/${this.post.id}` : ""

    return `${this.domain}/api/farcaster/v2/subscribe/${this.blog.id}${postIdAppend}`
  }

  /**
   * Generates the URL for subscribing to the blog via an email input field.
   * @returns Returns the URL for subscribing to the blog via email.
   */
  subscribeWithEmail() {
    const postIdAppend = this.post ? `/${this.post.id}` : ""

    return `${this.domain}/api/farcaster/v2/subscribe/email-input/${this.blog.id}${postIdAppend}`
  }

  subscribeSkipEmail() {
    const postIdAppend = this.post ? `/${this.post.id}` : ""

    return `${this.domain}/api/farcaster/v2/subscribe/email-skip/${this.blog.id}/${postIdAppend}`
  }

  subscribeToRecommendations(toBlogId: string) {
    return `${this.domain}/api/farcaster/v2/recommendations/blog/${this.blog.id}/subscribe/${toBlogId}`
  }

  mintButtons() {
    if (!this.post) {
      this.logger.error(
        "Cannot generate mint button because the post is undefined."
      )

      return this.origin()
    }

    return `${this.domain}/api/farcaster/v2/mint-buttons/blog/${this.blog.id}/post/${this.post.id}`
  }

  mint() {
    if (!this.post) {
      this.logger.error(
        "Cannot generate mint URL because the post is undefined."
      )

      return this.origin()
    }

    return `${this.domain}/api/farcaster/v2/mint/blog/${this.blog.id}/post/${this.post.id}`
  }

  mintCallback() {
    if (!this.post) {
      this.logger.error(
        "Cannot generate mint callback URL because the post is undefined."
      )

      return this.origin()
    }

    return `${this.domain}/api/farcaster/v2/mint-callback/blog/${this.blog.id}/post/${this.post.id}`
  }

  /**
   * This generates the URL for the "share" button, with the referrer appended.
   *
   * This works for both blog and post level shares.
   */
  warpcastShareURL({
    casterEthAddr,
    authorFarcasterUsername,
  }: {
    /**
     * This is the eth address of the new caster, for referral rewards.
     */
    casterEthAddr?: string
    /**
     * This is the FC username of the author of a post.
     *
     * We tag them when users share.
     */
    authorFarcasterUsername?: string
  }) {
    if (!casterEthAddr) {
      this.logger.error(
        {
          post: this.post,
          casterEthAddr,
        },
        "Cannot generate warpcast share URL because the caster eth address is undefined."
      )

      return this.origin()
    }

    const blogOrPostUrl = encodeURIComponent(
      this.getBlogOrPostUrlWithReferrer(casterEthAddr)
    )

    let text = `I just subscribed to ${this.blog.name} on /paragraph! Check it out:`
    if (authorFarcasterUsername) {
      text = `I just subscribed to @${authorFarcasterUsername} on /paragraph! Check it out:`
    }

    text = encodeURIComponent(text)

    return `https://warpcast.com/~/compose?embeds[]=${blogOrPostUrl}&text=${text}`
  }

  /**
   * Returns the URL to either the blog, or the blog's specific post (if it exists), with the referrer appended.
   * @param ethAddr The eth address of the referrer.
   * @returns The URL to either the blog, or the blog's specific post (if it exists), with the referrer appended.
   */
  getBlogOrPostUrlWithReferrer(ethAddr?: string) {
    const noteUrl = this.post?.slug || this.post?.id

    let url = this.domain

    // If this is not a custom domain, then let's append the lowercase URL e.g. @blog
    if (!this.blog.custom_domain) {
      url += "/" + this.blog.lowercase_url
    }

    if (noteUrl) {
      url += "/" + noteUrl
    }

    if (ethAddr) {
      url = url + "?referrer=" + ethAddr
    }

    return url
  }
}

import { Blog, BlogRecommendation } from "@types"
import type Logger from "bunyan"
import { FrameButtons } from "./buttons"
import {
  FramePost,
  FramePostWithJson,
  FrameResponseTypes,
  FrameUser,
  MetaTag,
  RESPONSES,
} from "./types"
import { baseDomain } from "./helpers"
import { FrameImages } from "./images"
import { FrameURLs } from "./urls"
import { CHAINS, Collectible } from "@/types/highlights"
import { getChainId } from "../crypto"

/**
 * Generates the meta tags for various frame responses.
 *
 * These are generated for both XMTP's Open Frames and Farcaster Frames.
 *
 * This lives in a shared folder because frame tags are returned both from the API
 * and from the frontend (the initial frame response always comes from the front-end).
 * For consistent frame responses, we need to ensure that the tags are generated in the same way.
 *
 * There are two flows currently which would generate a frame response:
 * 1. Someone shares a URL to the blog, in which case we provide a frame response with a subscribe button.
 *    If the user subscribes, we then show them any recommended publications set by that blog.
 *    NOTE: In this situation, there would be no post provided.
 *
 * 2. Someone shares a URL to a post, in which case we provide a frame response with various buttons,
 *    allowing the reader to read the post inline, read the post online, subscribe to the blog, or mint
 *    the post.
 */
export class FrameResponseGenerator {
  /*****------------*****/
  /*****   FIELDS   *****/
  /*****------------*****/

  /**
   * The logger object.
   */
  private _logger: Logger

  /**
   * The blog object.
   */
  private _blog: Blog

  /**
   * This gets populated in the constructor because deriving this from the Blog is trivial and does not require
   * a DB call.
   */
  private _blogOwnerUserId: string

  /**
   * The domain of the API, e.g. https://paragraph.xyz
   * Fetched in the constructor from the blog object, so no need to pass it in.
   */
  private _domain: string

  /**
   * The post object. Must contain some minimum set of fields (see the type).
   * This is an optional field because if the frame was generated for a blog and not a post, this field will be undefined.
   */
  private _post?: FramePost | FramePostWithJson

  /*****-------------------------*****/
  /*****   COMPOSITION CLASSES   *****/
  /*****-------------------------*****/

  private buttons: FrameButtons
  private images: FrameImages
  private urls: FrameURLs

  /*****-----------------*****/
  /*****   CONSTRUCTOR   *****/
  /*****-----------------*****/

  /**
   * Creates a new FrameResponse object.
   * @param logger The logger object.
   * @param blog The blog object.
   * @param blogOwnerUserId The user ID of the blog owner.
   * @param post The post object. Must contain some minimum set of fields (see the type).
   *             If the frame response is for a blog and not a post, this field will be undefined.
   */
  constructor({
    logger,
    blog,
    blogOwnerUserId,
    post,
  }: {
    logger: Logger
    blog: Blog
    blogOwnerUserId: string
    post?: FramePostWithJson | FramePost
  }) {
    // Required fields.
    this._logger = logger
    this._blog = blog
    this._blogOwnerUserId = blogOwnerUserId
    this._domain = baseDomain(blog)

    // Optional fields.
    this._post = post

    // Set the logger fields.
    this._logger = this._logger.child({
      blogId: this._blog.id,
      blogOwnerUserId: this._blogOwnerUserId,
      postId: this._post?.id,
      domain: this._domain,
    })

    // Instantiate the various child classes this class is composed of.
    // We're using composition here because TypeScript does not support multiple inheritance and MixIns seem to be
    // a bit of a hack and hard ot read.
    this.images = new FrameImages({
      blog: this._blog,
    })

    this.urls = new FrameURLs({
      logger: this._logger,
      blog: this._blog,
      domain: this._domain,
      post: this._post,
    })

    this.buttons = new FrameButtons({
      urls: this.urls,
      logger: this._logger,
      blog: this._blog,
      post: this._post,
    })
  }

  /*****-------------*****/
  /*****   GETTERS   *****/
  /*****-------------*****/

  // We're already fetching these prior to initializing the constructor, which on the back-end happens in middleware
  // before any route logic is hit. Since the routes themselves often need these objects, making them accessible here
  // reduces the need for redundant fetching.
  // Alternatively, we could have added them on the Request object, but since these would have only been populated for
  // a small minority of routes, it would have been misleading to have them on the Request object.

  /**
   * Returns the blog object.
   */
  get blog(): Blog {
    return this._blog
  }

  /**
   * Returns the blog owner user ID.
   */
  get blogOwnerUserId(): string {
    return this._blogOwnerUserId
  }

  /**
   * Returns the domain of the API.
   */
  get domain(): string {
    return this._domain
  }

  /**
   * Returns the post object.
   */
  get post(): FramePostWithJson | undefined {
    return this._post
  }

  /*****--------------------*****/
  /*****   PUBLIC METHODS   *****/
  /*****--------------------*****/

  /**
   * Generates a string with meta tags for a server frame response. This will include <html>, <head>, and <body> tags.
   */
  generateServerFrameMetaTags(responseDetails: FrameResponseTypes): string {
    // Since we're not rendering on the front-end, we need to include the full HTML structure.
    let response = `
      <!DOCTYPE html>
      <html>
      <head>`

    // Insert all the meta tags into the <head>.
    const metaTags = this.generateMetaTags(responseDetails)

    response += metaTags
      .map(
        (tag) => `<meta property="${tag.property}" content="${tag.content}" />`
      )
      .join()

    // Since we're not rendering on the front-end, we need to include the full HTML structure.
    response += `
      </head>
      <body>
      </body>
      </html>`

    return response
  }

  /**
   * Generates a string with meta tags for a front-end frame response. This will NOT include <html>, <head>, or <body> tags
   * because the front-end will insert these into the existing <head> tag.
   *
   * @returns An array of meta tags for the front-end frame response.
   *          We're returning an array of meta tags here because the front-end will need to insert these into the existing
   *          <head> tag as JSX elements.
   */
  generateFrontEndFrameMetaTags(
    responseDetails: FrameResponseTypes
  ): MetaTag[] {
    const response = this.generateMetaTags(responseDetails)

    return response
  }

  getPostUrlWithReferrer(ethAddr?: string) {
    return this.urls.getBlogOrPostUrlWithReferrer(ethAddr)
  }

  /*****----------------------*****/
  /*****   META TAG METHODS   *****/
  /*****----------------------*****/

  /**
   * Generates both the "fc:frame" and "of" meta tags for a given property and content.
   * fc is for Farcaster Frames. https://docs.farcaster.xyz/reference/frames/spec
   * of is for XMTP's Open Frames. https://xmtp.org/docs/build/frames#frameworks
   * @param property The property of the meta tag.
   * @param content The content of the meta tag.
   * @returns The dual-prefix meta tag.
   */
  private generateDualPrefixMetaTag(
    property: string,
    content: string
  ): MetaTag[] {
    return [
      { property: `fc:frame:${property}`, content: `${content}` },
      { property: `of:${property}`, content: `${content}` },
    ]
  }

  /**
   * Generates an array of dual-prefix meta tags.
   * @param tags The array of meta tags.
   * @returns The dual-prefix meta tags.
   */
  private generateArrayOfDualPrefixMetaTags(tags: MetaTag[]): MetaTag[] {
    return tags.flatMap((tag) =>
      this.generateDualPrefixMetaTag(tag.property, tag.content)
    )
  }

  /**
   * Base tags that are ALWAYS present, whether we're generating a front-end or back-end frame response,
   * and regardless of the content of the frame.
   */
  private baseTags(image?: string, otherTags?: MetaTag[]): MetaTag[] {
    return [
      // Needed for XMTP's Open Frames.
      { property: "of:accepts:xmtp", content: "2024-02-01" },

      { property: "fc:frame", content: "vNext" },
      { property: "of:version", content: "vNext" },

      ...(image
        ? [
            { property: "fc:frame:image", content: `${image}` },
            { property: "of:image", content: `${image}` },
          ]
        : []),

      // If there are other tags, add them here.
      ...(otherTags || []),
    ]
  }

  /**
   * Generates the meta tags for a given frame response.
   * These can then be adapted for use by the front-end or the back-end.
   *
   * This is the primary entry point for generating meta tags for a frame response.
   */
  private generateMetaTags(responseDetails: FrameResponseTypes): MetaTag[] {
    let response: MetaTag[] = []
    let image = ""
    // Even though we've specified the post_url to open on the button itself,
    // unfortunately farcaster is ignoring this and relying entirely on the post_url meta tag.
    // when the type is post_redirect, so we need to set it here as otherwise all post_redirect
    // requests will fail.
    let postUrl = this.urls.openPost()

    switch (responseDetails.type) {
      case RESPONSES.HOMEPAGE_BLOG:
        image = responseDetails.image
        postUrl = this.urls.origin()

        response = this.generateBlogHomePage()

        break

      case RESPONSES.HOMEPAGE_POST:
        image = responseDetails.image

        response = this.generatePostHomePage(
          // responseDetails.isPostLevelGate || false // TODO: When we have post-level gating, we'll need to pass this in.
          false
        )

        break

      case RESPONSES.READ_INLINE_PAGE:
        image = responseDetails.image

        response = this.generateReadInlinePage(
          responseDetails.currentPage,
          responseDetails.denyAccess || false
        )

        break

      case RESPONSES.READ_INLINE_LAST_PAGE:
        image = responseDetails.image

        response = this.generateReadInlineLastPage(responseDetails.currentPage)

        break

      case RESPONSES.ADD_EMAIL:
        image = this.images.populateShareImage(
          "What's your email?",
          responseDetails.blogOwnerUser
        )

        response = this.generateAddEmailPage()

        break

      case RESPONSES.RECOMMENDATIONS:
        // Sanity check to ensure we're only doing 4 recommendations.
        const limitedRecs = responseDetails.blogRecommendations.slice(0, 4)

        image = this.images.getRecommendationImage({
          recommendingBlog: this._blog,
          recommendations: limitedRecs,
        })

        response = this.generateRecommendationsPage(limitedRecs)

        break

      case RESPONSES.MINT_BUTTONS:
        image = responseDetails.image

        response = this.generateCollectPostPage(responseDetails.collectible)
        break

      case RESPONSES.SUCCESS_MINT:
        response = this.generateGenericSingleButtonPage({
          buttonText: "Minted!",
          description: "You've collected this post.",
          url: this.urls.origin(),
          blogOwnerUser: responseDetails.blogOwnerUser,
          openPost: true,
        })

        break

      case RESPONSES.SUCCESS_SUBSCRIBED:
        response = this.generateGenericSingleButtonPage({
          buttonText: "Subscribed!",
          description:
            "Subscribed with wallet! Download Coinbase Wallet to receive updates via XMTP.",
          url: this.urls.origin(),
          blogOwnerUser: responseDetails.blogOwnerUser,
          openPost: true,
        })

        break

      case RESPONSES.SHARE_FARCASTER_AFTER_SUBSCRIBE:
        response = this.generateGenericSingleButtonPage({
          buttonText: "Share",
          description: responseDetails.description,
          url: this.urls.warpcastShareURL({
            casterEthAddr: responseDetails.casterEthAddr,
            authorFarcasterUsername: responseDetails.authorFarcasterUsername,
          }),
          blogOwnerUser: responseDetails.blogOwnerUser,
          shareCast: true,
        })

        break

      case RESPONSES.ERROR:
        response = this.generateGenericSingleButtonPage({
          buttonText: "Error occurred",
          description: responseDetails.errorMsg,
          blogOwnerUser: responseDetails.blogOwnerUser,
          url: this.urls.origin(), // On error, the button should just go back to the homepage.
        })

        break

      default:
        throw new Error(`Invalid frame response type: ${responseDetails}`)
    }

    const otherTags: MetaTag[] = [
      ...response,

      ...this.generateDualPrefixMetaTag("post_url", postUrl),
    ]

    return this.baseTags(image, otherTags)
  }

  /*****------------------*****/
  /*****   PAGE METHODS   *****/
  /*****------------------*****/

  /**
   * Generates the home page for the blog frame returned by the front-end.
   * @returns The meta tags for the home page.
   */
  private generateBlogHomePage(): MetaTag[] {
    return this.generateArrayOfDualPrefixMetaTags([
      ...this.buttons.subscribe(1),
    ])
  }

  /**
   * Generates the first home page for the post frame returned by the front-end,
   * or by the back-end when a user clicks "back" to go back to the original frame.
   * @returns The meta tags for the home page.
   */
  private generatePostHomePage(isPostLevelGate: boolean): MetaTag[] {
    return this.generateArrayOfDualPrefixMetaTags([
      { property: "image:aspect_ratio", content: "1:1" },
      ...this.buttons.openPost(1),
      ...this.buttons.subscribe(2),
      ...this.buttons.mint(3),
    ])
  }

  /**
   * Generates the meta tags for the page of an inline read frame.
   * @param currentPage The current page of the post.
   * @param denyAccess Whether we should deny the user access to the post. This is true if either:
   *                   1. The post is post-level gated AND the user doesn't have access.
   *                   2. The post is after-level gated AND the user doesn't have access
   *                      AND they're trying to see a page after the gate.
   * @returns Returns the meta tags for the page of an inline read frame.
   */
  private generateReadInlinePage(
    currentPage: number,
    denyAccess: boolean
  ): MetaTag[] {
    // If the post is post-level gated, we don't show the "forward" button.
    // And the image passed in should already have text explaining that the post is gated.
    if (denyAccess) {
      return this.generateArrayOfDualPrefixMetaTags([
        ...this.buttons.back(1, currentPage),
        ...this.buttons.openPost(2),
        ...this.buttons.subscribe(3),
      ])
    }

    return this.generateArrayOfDualPrefixMetaTags([
      { property: "image:aspect_ratio", content: "1:1" },
      ...this.buttons.back(1, currentPage),
      ...this.buttons.forward(2, currentPage),
      ...this.buttons.openPost(3),
      ...this.buttons.subscribe(4),
    ])
  }

  /**
   * Generates the meta tags for the last page of an inline read frame.
   * @returns Returns the meta tags for the last page of an inline read frame.
   */
  private generateReadInlineLastPage(currentPage: number): MetaTag[] {
    return this.generateArrayOfDualPrefixMetaTags([
      ...this.buttons.back(1, currentPage),
      ...this.buttons.openPost(2),
      ...this.buttons.subscribe(3),
    ])
  }

  /**
   * Generates the meta tags for the "add email" page used to subscribe the user.
   * @returns Returns the meta tags for the "add email" page used to subscribe the user.
   */
  private generateAddEmailPage(): MetaTag[] {
    return this.generateArrayOfDualPrefixMetaTags([
      ...this.inputEmail(),
      ...this.buttons.skip(1),
      ...this.buttons.subscribeWithEmail(2),
    ])
  }

  /**
   * Generates the meta tags for the recommendations page that shows other recommended publications to the user.
   * @param blogRecommendations Publications recommended by this blog.
   * @returns Returns the meta tags for the recommendations page.
   */
  private generateRecommendationsPage(
    blogRecommendations: BlogRecommendation[]
  ): MetaTag[] {
    const buttons = blogRecommendations.map((rec, i) =>
      this.buttons.subscribeRecommendation(i + 1, rec)
    )

    const metaTags: MetaTag[] = buttons.flat()

    metaTags.push({
      property: "image:aspect_ratio",
      content: "1:1",
    })

    return this.generateArrayOfDualPrefixMetaTags(metaTags)
  }

  /**
   * Generates the meta tags for the collect post frame response.
   */
  private generateCollectPostPage(collectible?: Collectible) {
    const MINT_WITH_WARPS_CHAINS: Array<CHAINS> = ["base", "zora"]

    const buttons = [...this.buttons.mintWithCrypto(1)]

    if (
      collectible &&
      MINT_WITH_WARPS_CHAINS.includes(collectible.chain) &&
      "contractAddress" in collectible
    ) {
      const chainId = getChainId(collectible.chain)

      buttons.push(
        ...this.buttons.mintWithWarps(2, chainId, collectible.contractAddress)
      )
    }

    return this.generateArrayOfDualPrefixMetaTags(buttons)
  }

  /**
   * Generates the meta tags for a generic single-button page.
   * @returns Returns the meta tags for a generic single-button page.
   */
  private generateGenericSingleButtonPage({
    buttonText,
    description,
    url,
    blogOwnerUser,
    openPost = false,
    shareCast = false,
  }: {
    buttonText: string
    description: string
    /**
     * If url is populated, then buttonText becomes a 'link' button that
     * directs the user to the url.
     */
    url?: string
    blogOwnerUser?: FrameUser
    /**
     * If openPost is true, then the button will open the post.
     */
    openPost?: boolean
    /**
     * If shareCast is true, then the button will share this post via a cast with some pre-filled text.
     */
    shareCast?: boolean
  }): MetaTag[] {
    const share_img = this.images.populateShareImage(description, blogOwnerUser)

    const button = this.generateArrayOfDualPrefixMetaTags(
      openPost
        ? this.buttons.genericButtonThatOpensPost(1, buttonText)
        : shareCast && url
        ? this.buttons.genericShareButton(1, buttonText, url)
        : this.buttons.genericButton(1, buttonText, url)
    )

    return this.baseTags(share_img, button)
  }

  /*****------------------*****/
  /*****   INPUT FIELDS   *****/
  /*****------------------*****/

  // Note: If I ever end up with more than a single function here, it'll be worth
  // extracting this into its own class, similar to buttons and URLs.

  /**
   * Constructs the input field for the email subscription page.
   * @returns Returns the input field for the email subscription page.
   */
  private inputEmail() {
    return [{ property: "input:text", content: "Email..." }]
  }
}
